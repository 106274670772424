import { Controller } from '@hotwired/stimulus'
import modernizr from 'modernizr'

import { register } from 'swiper/swiper-element-bundle';
// register Swiper custom elements
register();

export default class extends Controller {


   static targets = ['thumbContainer', 'thumbSpan', 'thumbSlider', 'fullscreenButton']

   static values = {
     thumbCount: Number,
     thumbPageCount: Number,
     currentThumbPage: Number
   }


  thumbSliderTargetConnected(element) {
    let that = this;

    const swiperEl = document.querySelector('swiper-container');

    // swiper parameters
    const swiperParams = {
      slidesPerView: 1,
      loop: true
    };

    Object.assign(swiperEl, swiperParams);
    let swiper = swiperEl.swiper;

    swiper.on('slideChange', function (e) {
      let currentSlide = e.realIndex + 1;

      if (isNaN(currentSlide)) {
        currentSlide = 1;
        swiper.slideToLoop(0);
      }
      let newThumbSlide = Math.floor((currentSlide + 19) / 20);
      that.updateThumbsPage(newThumbSlide);
      that.analyticsController.trackPage();
    });

    document.addEventListener('keydown', function(event) {
      if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
        event.preventDefault();
      }
    });

    document.addEventListener('keyup', function(event) {
      var slidesContainer = document.getElementById('slides');
      var isFullScreen = slidesContainer.classList.contains('fullscreen');

      if (!isFullScreen) {

        if (event.key == 'ArrowUp' || event.key == 'ArrowDown') {
          event.preventDefault();
        }

        let currentSlideIndex = swiper.realIndex;

        if (event.key == 'ArrowUp') {
          var newSlideIndex = swiper.realIndex - 4;
          newSlideIndex = newSlideIndex < 0 ? that.thumbCountValue - 1 : newSlideIndex;
          swiper.slideToLoop(newSlideIndex);
        }

        if (event.key == 'ArrowDown') {
          var newSlideIndex = swiper.realIndex + 4;
          newSlideIndex = newSlideIndex > that.thumbCountValue ? 0 : newSlideIndex;
          swiper.slideToLoop(newSlideIndex);
        }

      }

    });
  }

  updateThumbsPage(slideIndex) {
    this.thumbContainerTarget.classList.remove('page-' + this.currentThumbPageValue);
    this.thumbContainerTarget.classList.add('page-' + slideIndex);
    this.currentThumbPageValue = slideIndex;
    this.updatePageInfo();
  }

  advance() {
      let nextPage = this.currentThumbPageValue >= this.thumbPageCountValue ? 1 : this.currentThumbPageValue + 1
      this.updateThumbsPage(nextPage);
   }

   back() {
      let nextPage = this.currentThumbPageValue <= 1 ? this.thumbPageCountValue : this.currentThumbPageValue - 1
      this.updateThumbsPage(nextPage);
   }

  updatePageInfo() {
    if (this.hasThumbSpanTarget) {
      let start = ((this.currentThumbPageValue - 1) * 20) + 1;
      let end = (start + 19) > this.thumbCountValue ? this.thumbCountValue : (start + 19);
      let range =  start + ' - ' + end;
      this.thumbSpanTarget.innerText = range;
    }
  }

  fullscreenButtonTargetConnected() {
    document.addEventListener('fullscreenchange', fullscreenHandler);
    document.addEventListener('webkitfullscreenchange', fullscreenHandler);
    document.addEventListener('mozfullscreenchange', fullscreenHandler);
    document.addEventListener('MSFullscreenChange', fullscreenHandler);

    // Setup keyboard shortcuts
    document.addEventListener('keydown', addKeyboardShortcuts);

    document.addEventListener('turbo:before-cache', function() {
      document.removeEventListener('keydown', addKeyboardShortcuts);
    });
  }


  toggleFullscreen () {
    // const slider = document.querySelector('swiper-container');
    var el = document.getElementById('slides');
    var isFullScreen = el.classList.contains('fullscreen');

    if (modernizr.fullscreen) {
      isFullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;

      el.classList.add('transitioning');

      setTimeout(function() {
        el.classList.remove('transitioning');
      }, 800);
    }


    if (!isFullScreen) {
       enterFullscreen(el);
     } else {
       exitFullscreen(el);
     }

  }

  get analyticsController() {
    var etjsElement = document.getElementById('et');
    return this.application.getControllerForElementAndIdentifier(etjsElement, 'analytics')
  }
}




function addKeyboardShortcuts(e) {
  if(bodyHasFocus()) {
    if (e.which == 70 && !e.ctrlKey && !e.metaKey) { // F for fullscreen
      enterFullscreen();
    } else if (e.which >= 49 && e.which <= 52) { // 1-4 to navigate slider tabs (photos, 3DTours, video, streetview, etc.)
      triggerAccessKey(e.key);
    }
  }
}

function fullscreenHandler() {
  var sliderContainer = document.getElementById('slides');
  if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
    sliderContainer.classList.remove('fullscreen');
    exitFullscreen();
  } else {
    sliderContainer.classList.add('fullscreen');
    enterFullscreen();
  }
}


function enterFullscreen(slider) {

  toggleSliderImageVariant(true);

  var sliderContainer = slider ? slider : document.getElementById('slides');
  if (sliderContainer.requestFullscreen) {
    sliderContainer.requestFullscreen();
    sliderContainer.classList.add('fullscreen');
  } else if (sliderContainer.mozRequestFullScreen) { /* Firefox */
    sliderContainer.mozRequestFullScreen();
    sliderContainer.classList.add('fullscreen');
  } else if (sliderContainer.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
    sliderContainer.webkitRequestFullscreen();
    sliderContainer.classList.add('fullscreen');
  } else if (sliderContainer.msRequestFullscreen) { /* IE/Edge */
    sliderContainer.msRequestFullscreen();
    sliderContainer.classList.add('fullscreen');
  } else {
    jQuery('body').addClass('fullscreen');
    sliderContainer.classList.add('fullscreen');
  }

  // Set caption position
  if (modernizr.fullscreen) {
    setTimeout(function() {
      if (document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen) {
        var scheight = jQuery('.swiper-container').height();
        var swheight = jQuery('.swiper-wrapper').height();

        var captionOffset = swheight - scheight;
        jQuery('.swiper-caption').css({
          'position': 'relative',
          'bottom': captionOffset + 'px'
        });
        if (slider) {
          slider.updateSize()
        }
      }

    }, 800);
  }

}

function exitFullscreen(slider) {

  exitFullscreenHandler(slider);
  if (document.exitFullscreen)  {
    document.exitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.webkitCancelFullScreen) {
    document.webkitCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  } else {
    jQuery('body').removeClass('fullscreen');
    exitFullscreenHandler();
  }

  // Reset caption position
  if (modernizr.fullscreen) {
    setTimeout(function() {
        if (!document.fullScreen || !document.mozFullScreen || !document.webkitIsFullScreen) {
            jQuery('.swiper-caption').css('position', 'static');
          }
        }, 800);
  }

}

function toggleSliderImageVariant(isFullscreen = false) {

  var slideImages = document.querySelectorAll('#slides swiper-slide img');
  [].forEach.call(slideImages, (img) => {
    if (isFullscreen) {
      let fullscreenSrc = img.dataset.fullscreenSrc;
      if (fullscreenSrc != undefined) {
        img.src = fullscreenSrc;
      }
    } else {
      let defaultSrc = img.dataset.defaultSrc;
      if (defaultSrc != undefined) {
        img.src = defaultSrc;
      }
    }
  })

}


function bodyHasFocus() {
  return document.activeElement.tagName == 'BODY'
}

function exitFullscreenHandler(slider) {

  toggleSliderImageVariant(false);

  var sliderContainer = slider ? slider : document.getElementById('slides')
  sliderContainer.classList.remove('fullscreen');

  if (modernizr.fullscreen) {
    var captions = document.getElementsByClassName('swiper-caption');
    [].forEach.call(captions, function(caption){
      caption.style.position = 'static';
    })
  }
}

function triggerAccessKey(key) {
  var link = document.querySelector('a[accesskey="'+key+'"]');
  if (link) {
    link.click();
  }
}
